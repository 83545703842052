import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["video", "iconContainer", "iconOff", "iconOn"]
  static values = {
    withSound: {
      type: Boolean,
      default: false
    },
    activeVideo: {
      type: Boolean,
      default: false
    },
    forceAutoplay: {
      type: Boolean,
      default: false
    }
  }

  initialize() {
    document.addEventListener("gallery-inline:slideChanged",  this.refreshStatus.bind(this))
    document.addEventListener("slider:slideChanged",  this.refreshStatus.bind(this))
  }

  refreshStatus({ detail: { sliderId, slideIndex } }) {
    if (this.forceAutoplayValue) return

    if (this.element.dataset.sliderId == sliderId && this.element.dataset.slideIndex == slideIndex) {
      this.videoTarget.play()
    } else if (this.element.dataset.sliderId == sliderId) {
      if (!this.videoTarget.muted) {
        this.toggleSound()
      }
      this.videoTarget.pause()
    }
  }

  connect() {
    if (this.forceAutoplayValue) {
      this.videoTarget.play()
      return
    }

    if (this.element.dataset.slideIndex == 0) {
      this.videoTarget.play()
    }
  }

  activeVideoValueChanged() {
    if (this.activeVideoValue) {
      this.videoTarget.play()
    } else {
      if (!this.videoTarget.muted) {
        this.toggleSound()
      }
      this.videoTarget.pause()
    }
  }

  toggleSound() {
    if (this.withSoundValue !== true) return

    this.videoTarget.muted = !this.videoTarget.muted

    if (this.videoTarget.muted) {
      this.iconOffTarget.style.opacity = 1
      this.iconOnTarget.style.opacity = 0
      this.iconContainerTarget.classList.remove("opacity-0")
    } else {
      this.iconOffTarget.style.opacity = 0
      this.iconOnTarget.style.opacity = 1
      this.iconContainerTarget.classList.add("opacity-0")
    }
  }
}
