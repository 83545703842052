import { Controller } from "@hotwired/stimulus"
import KeenSlider from "keen-slider"
import { nanoid } from "nanoid"

export default class extends Controller {
  static targets = ["slider", "slide", "dots", "controls"]
  static values = {
    controls: {
      type: String,
      default: "none"
    },
  }

  slider = null

  connect() {
    const sliderId = nanoid()
    this.sliderTarget.dataset.sliderId = sliderId

    this.slider = new KeenSlider(this.sliderTarget, {
      loop: true,
      slides: {
        perView: 1
      },
      breakpoints: {
        "(min-width: 640px)": {
          slides: {
            perView: 2,
            spacing: 20,
          },
          mode: "free-snap",
        },
        "(min-width: 768px)": {
          slides: {
            perView: 3,
            spacing: 20,
          },
          mode: "free-snap",
        },
        "(min-width: 1024px)": {
          slides: {
            perView: 4,
            spacing: 20,
          },
          mode: "free-snap",
        },
      },
      created: (instance) => {
        const dots_wrapper = this.dotsTarget
        const slides = this.slideTargets

        if (this.controlsValue == "none")   this.controlsTarget.style.display = "none"
        if (this.controlsValue == "arrows") this.dotsTarget.style.display     = "none"

        slides.forEach((slide, index) => {
          slide.dataset.sliderId = sliderId
          slide.dataset.slideIndex = index

          const dot = document.createElement("button")
          dot.innerHTML = index + 1
          dot.classList.add("dot")
          dots_wrapper.appendChild(dot)
          dot.addEventListener("click", function () {
            instance.moveToSlide(index)
          })
        })
        this.updateClasses(instance)
      },
      slideChanged: (instance) => {
        this.dispatch("slideChanged", {
          detail: {
            sliderId,
            slideIndex: instance.track.details.rel
          }
        })
        this.updateClasses(instance)
      }
    })
  }

  prev() {
    this.slider.prev()
  }

  next() {
    this.slider.next()
  }

  updateClasses(instance) {
    const slide = instance.track.details.rel

    const dots = this.dotsTarget.childNodes
    dots.forEach((dot, idx) => {
      if (idx === slide) {
        dot.classList.add("dot--active")
      } else {
        dot.classList.remove("dot--active")
      }
    })
  }
}
