import { Controller } from "@hotwired/stimulus"
import KeenSlider from "keen-slider"
import { nanoid } from "nanoid"

export default class extends Controller {
  interval = 0

  static targets = ["slider", "slide", "dots", "controls"]
  static values = {
    controls: {
      type: String,
      default: "none"
    },
    automaticSliding: {
      type: Boolean,
      default: true
    },
    slidingSpeed: {
      type: Number,
      default: 2500
    },
    slidingDelay: {
      type: Number,
      default: 5000
    }
  }

  slider = null

  connect() {
    const sliderId = nanoid()
    this.sliderTarget.dataset.sliderId = sliderId

    this.slider = new KeenSlider(this.sliderTarget, {
      loop: true,
      duration: this.automaticSlidingValue ? this.slidingSpeedValue : 500,
      dragStart: (instance) => {
        this.automaticSlidingValue && this.autoplay(false, instance)
      },
      dragEnd: (instance) => {
        this.automaticSlidingValue && this.autoplay(true, instance)
      },
      created: (instance) => {
        const dots_wrapper = this.dotsTarget
        const slides = this.slideTargets

        if (this.controlsValue == "none")   this.controlsTarget.style.display = "none"
        if (this.controlsValue == "arrows") this.dotsTarget.style.display     = "none"

        slides.forEach((slide, index) => {
          slide.dataset.sliderId = sliderId
          slide.dataset.slideIndex = index

          const dot = document.createElement("button")
          dot.innerHTML = index + 1
          dot.classList.add("dot")
          dots_wrapper.appendChild(dot)
          dot.addEventListener("click", function () {
            instance.moveToSlide(index)
          })
        })
        this.updateClasses(instance)
      },
      slideChanged: (instance) => {
        this.dispatch("slideChanged", {
          detail: {
            sliderId,
            slideIndex: instance.track.details.rel
          }
        })
        this.dispatch("lazyLoad", {
          detail: {
            sliderId,
            slideIndex: instance.track.details.rel,
            totalSlides: this.slideTargets.length
          }
        })
        this.updateClasses(instance)
      }
    })

    if (this.automaticSlidingValue) {
      this.sliderTarget.addEventListener("mouseover", () => {
        this.autoplay(false, this.slider)
      })
      this.sliderTarget.addEventListener("mouseout", () => {
        this.autoplay(true, this.slider)
      })
      this.autoplay(true, this.slider)
    }
  }

  prev() {
    this.slider.prev()
  }

  next() {
    this.slider.next()
  }

  autoplay(run, slider) {
    clearInterval(this.interval)
    this.interval = setInterval(() => {
      if (run && slider) {
        slider.next()
      }
    }, this.slidingDelayValue)
  }

  updateClasses(instance) {
    const slide = instance.track.details.rel

    const dots = this.dotsTarget.childNodes
    dots.forEach((dot, idx) => {
      if (idx === slide) {
        dot.classList.add("dot--active")
      } else {
        dot.classList.remove("dot--active")
      }
    })
  }
}
